import { Route, Routes, useParams } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import News from "./Pages/News";
import SignUp from "./Pages/SignUp";
import LogIn from "./Pages/LogIn";
import Store from "./Pages/Store";
import SignUpComptetive from "./Pages/signup_comptetive";
import SignupComptetive5x5 from "./Pages/SignupComptetive5x5";
import LeagueHome from "./Pages/LeagueHome";
import LeagueInfo from "./Pages/LeagueInfo";
import LeaguePlayer from "./Pages/LeaguePlayer";
import LeagueTeam from "./Pages/LeagueTeam";
import SignUpIcon from "./Pages/SignUpIcon";
import SignUpPartner from "./Pages/SignUpPartner";
import SignUpWorkWithUs from "./Pages/SignUpWorkWithUS";
import SignUpVolunteer from "./Pages/SignUpVolunteer";
import SignUpLeague from "./Pages/SignUpLeague";
import SignUpLeague5x5 from "./Pages/SignUpLeague5x5";
import SignUpCamp from "./Pages/SignUpCamp";
import SignUpTournament from "./Pages/SignUpTournament";
import SignUpClinic from "./Pages/SignUpClinic";
import SignUpTrainingProgram from "./Pages/SignUpTrainingProgram";
import SignUpEvent from "./Pages/SignUpEvent";
import Tryout from "./Pages/Tryout";
import Tryout5X5 from "./Pages/Tryout5X5";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Rules from "./Pages/Rules";
import SingleNews from "./Pages/SingleNews";
import React, { useEffect } from "react";
import Blog from "./Pages/Blog/index";
import Cart from "./Pages/Cart";
import Session from "./Pages/Session";
import Payment from "./Pages/Payment";
import OrderPlace from "./Pages/OrderPlace";
import { Auth } from "aws-amplify";
import OrderPage from "./Pages/Order";
import OrderDetails from "./Pages/OrderDetails";
import PaymentFailure from "./Pages/PaymentFailure";
import { AllNews } from "./Pages/News/AllNews";
import Vlog from "./Pages/vlog";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadUser } from "./redux/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "./redux/actions/ProductAction";
import { getAllNews } from "./redux/actions/NewsAction";
import { getAllEvents } from "./redux/actions/EventsAction";
import { getCart } from "./redux/actions/cartAction";
import Loader from "./Components/Loader";
import { createContext } from "react";
import AuthRedirect from "./Components/AuthRedirect";
import { getAllPlayer } from "./redux/actions/PlayerAction";
import ScrollToTop from "./Components/ScrollTo";
import NotFoundPage from "./Pages/NotFoundPage";
import Profile from "./Pages/Profile";
import PaymentSuccess from "./Pages/PaymentSuccess";
import useHandlePagintationPlayers from "./hooks/handlePaginationPlayer";
import LeagueCity from "./Pages/LeagueCity";
import { getAllCity } from "./redux/actions/CityAction";
import Event from "./Pages/LeagueHome/Event";
import Players from "./Pages/Players";
import TournamentTeamByAgeGroup from "./Pages/TournamentTeamByAgeGroup";
import LeagueTeamByAgeCity from "./Pages/LeagueTeamByAgeCity";
import Clinics from "./Pages/Clinics/Clinics";
import ComptetiveDetails from "./Pages/ComptetiveDetails/ComptetiveDetails";
import ClinicDetails from "./Pages/Clinics/ClinicPage";
import LeagueDescription from "./Pages/LeagueDescription/LeagueDescription";
import League5x5Description from "./Pages/LeagueDescription/League5x5Description";
import CampDescription from "./Pages/CampDescription/CampDescription";
import TournamentDescription from "./Pages/TournamentDescription/TournamentDescription";
import TrainingProgramDescription from "./Pages/TrainingProgramDescription/TrainingProgramDescription";
import TrainingProgramDetails from "./Pages/TrainingProgramDescription/TrainingProgramDetails";
import CalendlyComponent from "./Components/CalendlyComponent";
import TournamentScore from "./Pages/ScoreTournament/ScoreTournament";
import Score from "./Pages/Score/Score";
import LatestTournaments from "./Pages/latestTournaments/LatestTournaments";
import ScheduleMeet from "./Pages/scheduleMeet/ScheduleMeet";
import PastTournaments from "./Pages/pastTournaments/PastTournaments";
import ScheduleDetails from "./Pages/Schedule/ScheduleDetails";

export const AuthContext = createContext(); // or createContext(defaultValue);
// added this

const initialState = {
  isAuthenticated: localStorage.getItem("auth") || false,
  email: localStorage.getItem("email") || null,
  token: localStorage.getItem("token") || null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("email", action.email);
      localStorage.setItem("auth", true);
      return {
        ...state,
        isAuthenticated: true,
        email: action.email,
      };
    case "LOGOUT":
      Auth.signOut()
        .then(() => {
          console.log("Successfully signed out");
        })
        .catch((err) => {
          console.log("Signout Error - ", err);
        });
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        email: null,
      };
    default:
      return state;
  }
};

const App = () => {
  const { isAuthenticated, loading, user } = useSelector(
    (state) => state?.user
  );
  const { alreadyPlayer, loading: alreadyPlayerLoading } =
    useHandlePagintationPlayers();

  // const [state, dispatch] = React.useReducer(reducer, initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
    dispatch(getAllProducts());
    dispatch(getAllNews());
    // dispatch(getAllCity());

    if (user) {
      // Check if this is an action creator that fetches news
      dispatch(getCart(user));
      dispatch(getAllPlayer());

      // Check if this is an action creator that fetches news
    }
    console.log("userAlready", alreadyPlayer);
  }, []);

  if (loading || alreadyPlayerLoading) {
    return <Loader />;
  }

  return (
    <div className="max-h-screen  overflow-y-auto overflow-x-hidden ">
      {/* <AuthContext.Provider
        value={{
          state,
          dispatch,
        }}
      > */}

      <ScrollToTop />

      <Header />
      <br />
      <br />
      <br />

      <div className="main-container font-roboto overflow-x-hidden">
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          {alreadyPlayer && (
            <Route
              path="/profile"
              element={<AuthRedirect children={<Profile />} />}
            />
          )}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/all" element={<AllNews />} />
          <Route path="/news/:id" element={<SingleNews />} />
          {/* {
            !isAuthenticated &&
            <Route path="/signup" element={<SignUp />} />
          } */}
          <Route path="/login" element={<LogIn />} />
          <Route path="/store" element={<Store />} />
          <Route path="/store/:productName" element={<Store />} />
          <Route path="/score/:id" element={<Score />} />
          <Route path="/tournamentsScore" element={<TournamentScore />} />
          <Route path="/leagues-score" element={<TournamentScore />} />
          <Route path="/past-tournaments" element={<PastTournaments />} />
          <Route path="/past-leagues" element={<PastTournaments />} />
          <Route path="/rules" element={<Rules />} />
          {/* <Route path="/store" element={<AllShop />} /> */}
          <Route path="/events" element={<LeagueHome />} />
          <Route path="/event/:id" element={<Event />} />
          <Route
            path="/scheduleMeet/training/:id"
            element={<AuthRedirect children={<ScheduleMeet />} />}
          />
          <Route
            path="/scheduleMeet/clinic/:id"
            element={<AuthRedirect children={<ScheduleMeet />} />}
          />
          <Route
            path="/scheduleMeet/camp/:id"
            element={<AuthRedirect children={<ScheduleMeet />} />}
          />
          <Route path="/vlog" element={<Vlog />} />
          <Route path="/vlog/:id" element={<Vlog />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/leagueinfo" element={<LeagueInfo />} />
          <Route path="/leagueplayer" element={<LeaguePlayer />} />
          <Route path="/players" element={<Players />} />
          <Route
            path="/tournament-teams-by-agegroup"
            element={<TournamentTeamByAgeGroup />}
          />
          <Route path="/league-teams" element={<LeagueTeamByAgeCity />} />
          <Route path="/leaguecity/:cityname" element={<LeagueCity />} />
          <Route path="/leagueteam" element={<LeagueTeam />} />
          <Route path="/leagueteam/:playerId" element={<LeagueTeam />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/clinics" element={<Clinics />} />
          <Route path="/comptetive-details" element={<ComptetiveDetails />} />
          <Route path="/league-details" element={<LeagueDescription />} />
          <Route path="/3x3-recreational" element={<LeagueDescription />} />
          <Route path="/5x5-recreational" element={<League5x5Description />} />
          <Route path="/camp-details" element={<CampDescription />} />
          <Route
            path="/tournament-details"
            element={<TournamentDescription />}
          />
            <Route
            path="/schedule-details"
            element={<ScheduleDetails />}
          />
          <Route
            path="/training-program-details"
            element={<TrainingProgramDescription />}
          />
          <Route path="/clinic/:id" element={<ClinicDetails />} />
          <Route
            path="/training-program/:id"
            element={<TrainingProgramDetails />}
          />
          <Route
            path="/signupicon"
            element={<AuthRedirect children={<SignUpIcon />} />}
          />
          <Route
            path="/signupworkwithus"
            element={<AuthRedirect children={<SignUpWorkWithUs />} />}
          />
          <Route
            path="/signuppartner"
            element={<AuthRedirect children={<SignUpPartner />} />}
          />
          <Route
            path="/signupvolunteer"
            element={<AuthRedirect children={<SignUpVolunteer />} />}
          />
           {/* <Route
            path="/signupcomtetive"
            element={<AuthRedirect children={<SignUpComptetive />} />}
          /> */}
             <Route
            path="/signupcomptetive"
            element={<SignUpComptetive />}
          />
            <Route
            path="/signupcomptetive5x5"
            element={<SignupComptetive5x5 />}
          />
          <Route
            path="/calendly"
            element={<AuthRedirect children={<CalendlyComponent />} />}
          />
          <Route
            path="/signupleague"
            element={
              alreadyPlayer ? (
                <AuthRedirect children={<Profile />} />
              ) : (
                <AuthRedirect children={<SignUpLeague />} />
              )
            }
          />
            <Route
            path="/signupleague5x5"
            element={
              alreadyPlayer ? (
                <AuthRedirect children={<Profile />} />
              ) : (
                <AuthRedirect children={<SignUpLeague5x5 />} />
              )
            }
          />
          <Route
            path="/signupcamp"
            element={<AuthRedirect children={<SignUpCamp />} />}
          />
          <Route
            path="/signuptournament"
            element={<AuthRedirect children={<SignUpTournament />} />}
          />
          <Route
            path="/signuptrainingprogram"
            element={<AuthRedirect children={<SignUpTrainingProgram />} />}
          />
          <Route
            path="/signupclinic"
            element={<AuthRedirect children={<SignUpClinic />} />}
          />
          <Route
            path="/signupevent"
            element={<AuthRedirect children={<SignUpEvent />} />}
          />

          <Route
            path="/signuptryout"
            element={<AuthRedirect children={<Tryout />} />}
          />
             <Route
            path="/comp3x3tryout"
            element={<AuthRedirect children={<Tryout />} />}
          />
             <Route
            path="/comp5x5tryout"
            element={<AuthRedirect children={<Tryout5X5 />} />}
          />
          <Route path="/cart" element={<AuthRedirect children={<Cart />} />} />
          {/* {!alreadyPlayer && ( */}
          <Route
            path="/session/:pageName"
            element={<AuthRedirect children={<Session />} />}
          />
          {/* )} */}
          <Route
            path="/order-success"
            element={<AuthRedirect children={<OrderPlace />} />}
          />
          <Route
            path="/payment-failure"
            element={<AuthRedirect children={<PaymentFailure />} />}
          />
          <Route
            path="/payment-success"
            element={<AuthRedirect children={<PaymentSuccess />} />}
          />
          <Route
            path="/payment"
            element={<AuthRedirect children={<Payment />} />}
          />
          <Route
            path="/order"
            element={<AuthRedirect children={<OrderPage />} />}
          />
          <Route
            path="/order/:id"
            element={<AuthRedirect children={<OrderDetails />} />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};
export default App;
