import HeaderLg from "../../Components/HeaderLg";
import SignUpWK from "../../Components/SignUpWK";
import SqShop from "../../Components/SqShop";
import TypoBu from "../../Components/TypoBu";
import Sponsors from "../../Components/Sponsors";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { NavLink } from 'react-router-dom';
import RenderShop from "../../Components/Shop/RenderShop";
import Advertisement from "../../Components/Advertisement";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";

const Rules = () => {
 
  return (
    <div className=" bg-white pb-10 scroll  ">
      <HeaderLg />
      <AdvertisementOne/>
      <div className="px-4 md:px-10 mt-10 lg:mt-20">
      <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
          <div className="lg:col-span-5 flex flex-col gap-10">
            <TypoBu
              item={{
                text: "Breaking Boundries 3v3 rules",
                text_ssize: "text-2xl",
                text_bsize: "text-3xl",
                border: true,
                button: false,
              }}
            />
            <div className=" flex flex-col text-lg px-6">
              <div>
                The following rules have been designed to ensure fair play for
                all participants and closely align with USA Basketball and FIBA
                competition rules. All players and coaches are expected to
                understand these rules prior to participating in 3v3 competition.
              </div>
              <div className="pt-10">
                <div className="pb-5 text-xl uppercase font-bold ">
                  Game Administration & Rules
                </div>
                <div className="pb-5">
                  <div className="font-bold">Roster Size</div>
                  <ul className="list-disc pl-5">
                    <li>5 player limit (must have 3 players to start the game)</li>
                  </ul>
                </div>

                <div className="pb-5">
                  <div className="font-bold">Referees</div>
                  <ul className="list-disc pl-5">
                    <li>1 required</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className=" font-bold">Time/Score Keepers</div>
                  <ul className="list-disc pl-5">
                    <li>1 required, 2 suggested (a parent from each team) or in
                      tournament play the referee will keep score</li>
                  </ul>
                </div>

                <div className="pb-5">
                  <div className="font-bold">Time-outs</div>
                  <ul className="list-disc pl-5">
                    <li>Each team is allowed one 30-second timeout per game. The
                      game clock does not stop during timeouts. NO timeouts are
                      permitted in the last three minutes of the game.</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">Initial Possession</div>
                  <ul className="list-disc pl-5">
                    <li>Coin flip (winning the coin flip allows a team to start the
                      game with the ball or decline in order to get the ball in a
                      potential overtime)</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <h3 className="font-bold">Game Duration & Scoring Limits</h3>
                  <ul className="list-disc pl-5">
                    <li>
                      League Operators will have the option to organize their games according to either format below:
                    </li>
                    <li className="pt-4">1x20/25 minutes</li>
                    <li>Running clock</li>
                    <li>The first team to 21 points (applies to regular playing time only) or the team with the most points at the end of the game</li>

                    <li className="pt-4">1x15 minutes</li>
                    <li>Running clock</li>
                    <li>The first team to 15 points (applies to regular playing time only) or the team with the most points at the end of the game</li>

                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">Overtime</div>
                  <ul className="list-disc pl-5">
                    <li>First team to score two (2) points wins</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">Scoring</div>
                  <ul className="list-disc pl-5">
                    <li>Baskets made from inside the arc count as one point. Baskets
                      made from behind the arc count as two points.</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">Substitutions</div>
                  <ul className="list-disc pl-5">
                    <li>Can occur in dead ball situations, prior to the check ball.
                      Players must enter the game at the top of the court.</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">Starting Possessions</div>
                  <ul className="list-disc pl-5">
                    <li> To start the game and after any dead ball that requires
                      checking the ball at the top, the ball must be passed (not
                      dribbled) from the check box at the rear of the court.</li>
                    <li>First violation: Warning from the referee or court
                      monitor</li>
                    <li>Each Additional Violation: Change of possession</li>
                  </ul>
                </div>


                <div className="font-bold">Shot Clock</div>
                <div className="pl-5 pb-5">
                  <div className="font-bold">Top Gun</div>
                  <ul className="list-disc pl-5">
                    <li>  12 seconds. The shot clock is to be monitored by the ref and
                      should be counted aloud for the final 5 seconds.</li>
                  </ul>
                </div>
                <div className="pl-5">
                  <div className="font-bold"> All ages</div>
                  <ul className="list-disc pl-5">
                    <li>  No shot clock – however, no stalling is allowed. The
                      referee, court monitor, or Jr. NBA staff member may institute
                      a 30 second clock at any time. After a team has been warned
                      about stalling, failure to hit the rim on the offensive
                      possession within 30 seconds will result in loss of
                      possession. The referee will count down the final 5 seconds.</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">Free Throws</div>
                  <ul className="list-disc pl-5">
                    <li> 1 free throw if fouled while shooting</li>
                    <li>2 free throws if fouled while shooting behind the arc</li>
                    <li>1 free throw if fouled while shooting and the shooter
                      makes the shot</li>
                    <li>Free throws count as one point</li>
                  </ul>
                </div>
               
                <div className="pl-5 pb-5">
                  <ul className="list-none pl-5">
                    <li className="font-bold list-disc">13U & Above</li>
                    <li>
                    Following a made free throw the ball is live as soon as the
                      ball goes through the basket and the opposing team can gain
                      possession and clear. If the free throw is missed, the ball is
                      live and the offensive team can rebound and play, or the
                      defensive team can rebound and clear.
                    </li>
                  </ul>
                </div>
                <div className="pl-5 pb-5">
                  <ul className="list-none pl-5">
                    <li className="font-bold list-disc">12U & Below</li>
                    <li>
                      Following a made free throw, the opposing team will check the
                      ball at the top. If the free throw is missed, the ball is live
                      and the offensive team can rebound and play, or the defensive
                      team can rebound and clear.
                    </li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">Jump Ball</div>
                  <ul className="list-disc pl-5">
                    <li> All jump balls become possession of the defensive team</li>
                  
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">Team Foul Limit (no individual fouls)</div>
                  <ul className="list-disc pl-5">
                    <li> 6 team fouls</li>
                  
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">  Penalty for Team Fouls 7-9</div>
                  <ul className="list-disc pl-5">
                    <li>2 free throws</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold"> Penalty for Team Fouls 10+</div>
                  <ul className="list-disc pl-5">
                    <li>2 free throws and possessions of the ball</li>
                  </ul>
                </div>
                
                <div className="pb-5 font-bold">
                  Possession Following A Successful Goal
                </div>
                <div className="pl-5 pb-5">
                  <ul className="list-none pl-5">
                    <li className="font-bold list-disc"> 13U & Above</li>
                    <li>
                    Defense gains possession (no make it – take it). The
                  possession begins right underneath the hoop when the defensive
                  team takes possession of the ball. Ball to be dribbled or
                  passed to a player behind the arc (both feet). Defensive team
                  not allowed to make a play for the ball inside the “no-charge
                  semicircle” (area under the basket).
                    </li>
                  </ul>
                </div>
                <div className="pl-5 pb-5">
                  <ul className="list-none pl-5">
                    <li className="font-bold list-disc"> 12U & Below</li>
                    <li>
                    Check ball exchange behind the arc (at the top)
                    </li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold"> Possession Following a Dead Ball</div>
                  <ul className="list-disc pl-5">
                    <li> Check ball exchange behind the arc (at the top)</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold">  Possession Following a Defensive Rebound or Steal</div>
                  <ul className="list-disc pl-5">
                    <li>Ball to be dribbled/passed behind the arc</li>
                  </ul>
                </div>
                <div className="pb-5">
                  <div className="font-bold"> Playoffs</div>
                  <ul className="list-disc pl-5">
                    <li> Players must play in at least 3 games to be eligible for the
                  playoffs</li>
                  </ul>
                </div>
                <div className="pb-5 font-bold">
                Coaching Rules
                </div>
                <div className="pl-5 pb-5">
                  <ul className="list-disc pl-5">
                    <li className="font-bold"> 13U & Above</li>
                    <li>
                    Coaches are allowed to provide guidance during timeouts, and
                  before and after the games. Coaches can also make
                  substitutions.
                    </li>
                    <li>Coaches will be penalized a point if a ref sees excessive
                  coaching during games.</li>
                  <li>Penalties will be assessed at referee’s discretion.</li>
                  </ul>
                </div>
                <div className="pl-5 pb-5">
                  <ul className="list-disc pl-5">
                    <li className="font-bold"> 12U & Below</li>
                    <li>
                    The same rules apply as the 13U and above, however refs will
                  be slightly more tolerant of coaching.
                    </li>
                    <li>o Coaches can be penalized a point if a ref sees
                  excessive coaching during the games.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-2">
          <Sponsors />
            <SignUpWK />
            <div className="my-10">
         <RenderShop/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Rules;
