import { BsTrophy } from "react-icons/bs";
import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { HiOutlineHandRaised } from "react-icons/hi2";
import Teams from "../../Components/Teams";
import TypoBu from "../../Components/TypoBu";
import Shop from "../../Components/Shop";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import Loader from "../../Components/Loader";
import useSignUpAsEvent from "../../hooks/signUpEvent";
import Advertisement from '../../Components/Advertisement';
import useGetAllCity from "../../hooks/getCityList";
import getLeaguesList from "../../hooks/getAllLeagues";
import {useNavigate} from 'react-router-dom'
import RenderShop from "../../Components/Shop/RenderShop";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

const SignUpEvent = () => {
  const { citylist } = useGetAllCity();
  const navigate=useNavigate();
  const { leagues } = getLeaguesList();
  const {
    user,
    userDetails,
    loading: userLoading,
    error: userError,
  } = useSelector((state) => state.user);

  const [description, setDescription] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [event, setEvent] = useState('');
  const [cityName, setCityName] = useState('');
  const [emailId, setEmailId] = useState(userDetails["email"] || "");
  const [agegroup, setAgegroup] = useState(userDetails["custom:age"] || "");
  const [ageGroupError, setAgeGroupError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(userDetails["phone_number"] || "");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");

  const [descriptionError, setDescriptionError] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [eventError, setEventError] = useState("");
  const [cityNameError, setCityNameError] = useState('');
  const { registerEventFunc,loading:creating } = useSignUpAsEvent();

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setPhoneNumberError("");

  };
  const handleEmailIdChange = (e) => {
    setEmailId(e.target.value);
    setEmailIdError("");
  };
  const handleAgeGroup = (e) => {
    setAgegroup(e.target.value);
    setAgeGroupError("");
  };
  const handleDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
    setDateOfBirthError("");

  };
  const handleEventChange = (e) => {
    setEvent(e.target.value);
    setEventError("");
  };

  const handleCityNameChange = (e) => {
    setCityName(e.target.value);
    setCityNameError("");
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionError("");
  };

  const handleRegister = async () => {
 
    const data={
      league:event,
      userId: user,
      cityName,
      email: emailId,
      transactionStatus:"PENDING",
      agegroup,
      phoneNumber,
      description,
      dateOfBirth
  
    }
  
    const checkRequiredFields = () => {
      const requiredFields = [
        cityName,
        description,
        event,
        emailId,
        phoneNumber,
        agegroup,
        dateOfBirth
      ];
  
      // Check if any required field is empty
      const isEmpty = requiredFields.some((field) => field === '');
  
      return !isEmpty; // Return true if all required fields are filled
    };
    const allRequiredFilled = checkRequiredFields();
    // console.log("Data",allRequiredFilled, data);
    if (allRequiredFilled) {
      await registerEventFunc(data);
    }
     
      setDescriptionError("");
      setEventError("");
      setCityNameError("");
      setDateOfBirthError("");
      setAgeGroupError("");
      setEmailIdError("");
      setPhoneNumberError("");

      let hasErrors = false; // Validation functions
  
      if (!cityName) {
        setCityNameError("City is required.");
        hasErrors = true;
      }

      if (!event) {
        setEventError("Event is required.");
        hasErrors = true;
      }
      if (!agegroup) {
        setAgeGroupError("Please enter the Age Group");
        hasErrors = true;
      }
      if (!phoneNumber) {
        setPhoneNumberError("Phone Number is required.");
        hasErrors = true;
      }
      if (!emailId) {
        setEmailIdError("Email Address is required.");
        hasErrors = true;
      }
      if (!dateOfBirth) {
        setDateOfBirthError("Date of Birth is required.");
        hasErrors = true;
      }
      if (!description) {
        setDescriptionError("Description is required.");
        hasErrors = true;
      }
    
  
      if (hasErrors) {
        // Display validation errors and prevent form submission
        return;
      } 
    }
    if(creating){
      return <Loader />;
    }
  return (
    
    <div>
      <div className="md:pr-2 mt-12 sm:pr-2 md:ml-4 md:pl-8 sm:ml-2 sm:pl-2 md:flex ">
        <div className="md:w-2/5 ml-2 mr-2">
          <div className="text-3xl font-bold">SIGN UP FOR EVENTS</div>

          <div className="mt-4">
            <h5>Name of the Event</h5>
            <select
              name="event"
              id="event"
              required
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={event}
              onChange={handleEventChange}
            >
              <option value="" defaultValue="">
                Choose option
              </option>

              {leagues?.map((value, index) => (
                <option value={value?.title} key={index}>
                  {value?.title}
                </option>
              ))}
            </select>
            <span className="text-red-500">{eventError}</span>
          </div>
          <div className="mt-4">
            <h5>Email Address</h5>
            <input
              name="email"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={emailId}
              
              onChange={handleEmailIdChange}
            />
          <span className="text-red-500 pb-2">{emailIdError}</span>

          </div>
          <div className="mt-4">
        <div> Phone Number</div>
        <input
            type="text"
            value={phoneNumber}
            className="border-[#e7e7e7] border-2 rounded-md p-2 w-full"
            placeholder=""
            onChange={handlePhoneNumberChange}
          />
          <div className="text-red-500 pb-2">{phoneNumberError}</div>
      </div>
      <div className="mt-4">
            <h5 className="">Age</h5>
            <select
              name="age"
              id="age"
              value={agegroup}
              onChange={handleAgeGroup}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
            >
              <option value="">
                Age
              </option>
              {ages.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}

            </select>
          </div>
          <span className="text-red-500 pb-2">{ageGroupError}</span>
          <div className="mt-4">
            <h5>City Name</h5>
            <select
              name="city_of_registration"
              id="city_of_registration"
              required
              value={cityName}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              onChange={handleCityNameChange}
            >
              <option value="" defaultValue="">
                Choose option
              </option>

              {citylist?.map((value, index) => (
                <option value={value.name} key={index}>
                  {value.name}
                </option>
              ))}
            </select>
            <span className="text-red-500">{cityNameError}</span>
            </div>
          <div className="mt-4">
            <h5>Date of birth</h5>
            <input
              type="date"
              value={dateOfBirth}
              className="border border-2"
              onChange={handleDateOfBirthChange}
            />
          </div>
          <span className="text-red-500 pb-2">{dateOfBirthError}</span>
          <div className="mt-4">
            <h5>Description</h5>

            <textarea id="message" rows="4" className="block p-2.5 text-sm text-black rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
              placeholder="text"  onChange={handleDescriptionChange}></textarea>
          <span className="text-red-500 pb-2">{descriptionError}</span>

          </div>
          <div className="flex pt-20 justify-center mb-5 ml-2 mr-2">
            <button onClick={handleRegister} className="font-bold  bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-1">Sign Up </button>
          </div>
        </div>
        <div className="md:w-3/5 p-4">
          <div className="text-center uppercase whitespace-nowrap text-xl font-bold">
            Sign Up For
          </div>
          <div className="text-center bg-white px-10 py-16">
            <div className="flex flex-wrap justify-center md:gap-[8rem]  md:flex-row flex-col ">
              <NavLink to="/signupleague" className="mb-2">
                <BsTrophy className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">
                  LEAGUE(Player)
                </div>
              </NavLink>
              <NavLink to="/signuptournament" className="mb-2">
                <BiBasketball className="inline text-4xl mb-4" />
                <div className="font-bold mb-4 text-sm whitespace-nowrap">
                  Tournament
                </div>
              </NavLink>
              <NavLink to="/signupcamp" className="mb-2">
                <FaCampground className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">
                  CAMPS
                </div>
              </NavLink>
              <NavLink to="/signupevent" className="mb-2">
                <img src="/images/Ticket.svg" alt="Ticket" className="inline h-12 mb-2 w-16" />
                <div className="font-bold text-sm mb-4">
                  EVENTS
                </div>
              </NavLink>
              <NavLink to="/signupvolunteer" className="mb-2">
                <HiOutlineHandRaised className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">
                  Volunteer
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#181B22] text-white">
        <div className="md:p-20">
          <AdvertisementOne index={0} />

        </div>
        <Teams />
        <div className="md:p-20 b-[#181B22]">
          <AdvertisementOne index={1} />
        </div>
        <div className="md:p-20 p-4 bg-[#181B22] text-white flex flex-col gap-20 py-20">
          <TypoBu
            item={{
              text: "shop",
              text_ssize: "text-3xl",
              text_bsize: "text-4xl",
              border: true,
              button: false,
            }}
          />
          <Shop />
          <div className="text-center">
            <Link to='/store' className="px-20 py-2 whitespace-nowrap border-white border rounded-sm">
              VIEW ALL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpEvent;
