import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { HiOutlineHandRaised } from "react-icons/hi2";
import { RiSuitcaseLine } from "react-icons/ri";
import Teams from "../../Components/Teams";
import Shop from "../../Components/Shop";
import { countryList } from "../../Components/SignUpDetail";
import { useState, useEffect, memo } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import TypoBu from "../../Components/TypoBu";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Components/Loader";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import { useHandleComptetivePlayers } from "../../hooks/handleComptetivePlayers";
import useGetAllCity from "../../hooks/getCityList";
import { useHandleComptetiveTeams } from "../../hooks/handleComptetiveTeams";
import uploadFile from "../../utils/uploadFile";

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const SignUpComptetive = () => {
  const navigate = useNavigate();
  const gender = ["male", "female"];
  const query = useQuery();
  const token = query.get("token"); // Replace "token" with the name of your query parameter

  const { loading: cityLoading, citylist } = useGetAllCity();
  const { loading: teamLoading, getAllTeams } = useHandleComptetiveTeams();
  const [cities, setCities] = useState();
  const [teams, setTeams] = useState();
  const [loading, setLoading] = useState(false);




  // const cities = ["city1", "city2"];
  const grade = [
    { value: "4th", label: "4th" },
    { value: "5th", label: "5th" },
    { value: "6th", label: "6th" },
    { value: "7th", label: "7th" },
    { value: "8th", label: "8th" },
    { value: "9th", label: "9th" },
    { value: "10th", label: "10th" },
    { value: "11th", label: "11th" },
    { value: "12th", label: "12th" }
  ];
    
  const jerseySizes = [
    { value: "YS", label: "YS" },
    { value: "YM", label: "YM" },
    { value: "YL", label: "YL" },
    { value: "S", label: "S" },
    { value: "M", label: "M" },
    { value: "L", label: "L" },
    { value: "XL", label: "XL" },
    { value: "XXL", label: "XXL" },
    { value: "XXXL", label: "XXXL" },
  ];
  

  const { loading: competitiveLoading, verifyTokenAndGetUserDetails } = useHandleComptetivePlayers();

  const howDidYouHearOptions = [
    { value: "From a Friend", label: "From a Friend" },
    { value: "Social Media", label: "Social Media" },
    { value: "Website", label: "Website" },
    { value: "Event or Workshop", label: "Event or Workshop" },
    { value: "Other", label: "Other" },
  ];
  


  const YesNo = [
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    }
  ]
  const familyOptions = [
    {value:"Mother",label:"Mother"},
    {value:"Father",label:"Father"},
    {value:"Son",label:"Son"},
    {value:"Daughter",label:"Daughter"},
    {value:"Brother",label:"Brother"},
    {value:"Spouse",label:"Spouse"}
  ];


  const [fields, setFields] = useState({
    id: "",
    userId: "",

    participantName: "",
    dateOfBirth: "",
    cityOfRegistration: "",
    agegroup: "",
    league: "",
    ext: "",
    teamID: "",
    profile: null,
    teamName: "",
    message: "",
    isAdult: false,
    grade: "",
    email: "",
    jerseySize: "",
    schoolName: "",
    isMemberOfOtherTeam: "",
    clubName: "",
    secondaryPersonName: "",
    primaryPersonName: "",
    isFirstYearPlaying: "",
    primaryCallPhoneNumber: "",
    secondaryCallPhoneNumber: "",
    primaryEmergencyContactEmail: "",
    secondaryEmergencyContactEmail: "",
    heardAboutLeague: "",
    healthConditions: "",
    parentFirstName: "",
    parentLastName: "",
    parentEmailAddress: "",
    parentPhoneNumber: "",
    parentBusinessPhoneNumber: "",
    parentHomePhoneNumber: "",
    parentCountry: "",
    signature: "",
    document: null,
    HEIGHT: "",
    WEIGHT: "",
    H_S: "",
    NBA: "",
    phoneNumber: "",
    COLLEGE: "",


  });
  const [selectedRelease, setSelectedRelease] = useState('');
  const [selectedActiveAgreement, setSelectedActiveAgreement] = useState('');


  const handleRegister = async (e) => {
    setLoading(true);
    try {


      e.preventDefault()
      console.log("Fields", fields);

      if (!fields.profile) {
        toast.error("Profile needed")
        return
      };

      if (!fields.document) {
        toast.error("Document needed")
        return
      };

      console.log("Fields", fields);

      const data = {
        ...fields,
        profile: await uploadFile(fields.profile),
        document: await uploadFile(fields.document),

      }
      localStorage.setItem("formData", JSON.stringify(data));
      navigate("/session/comptetive");

      console.log("Fields", fields);

    } catch (error) {
      console.log("Error", error);
      toast.error(error.message || error)
    } finally {
      setLoading(false);
    }
  };
  const handleReleaseChange = (e) => {
    setSelectedRelease(e.target.value);
  };

  // Handle changes for the Active Agreement and Waiver radio button
  const handleActiveAgreementChange = (e) => {
    setSelectedActiveAgreement(e.target.value);
  };



  const handleInputValue = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "checkbox") {

      setFields((previous) => ({
        ...previous,
        [name]: value === 'on' ? true : false,  // Store only the first file if multiple
      }));

    }
    else if (type === "file" && files.length > 0) {
      // Handle file input and ensure only the first file is used
      setFields((previous) => ({
        ...previous,
        [name]: files[0],  // Store only the first file if multiple
      }));
    } else {
      // Handle other input types
      setFields((previous) => ({
        ...previous,
        [name]: value,
      }));
    }
  };

  const tokenVerify = async () => {
    try {


      const data = await verifyTokenAndGetUserDetails(token);
      const teamsFind = await getAllTeams();

      console.log("TeamsFind", teamsFind)

      fields["email"] = data.email;
      fields["cityOfRegistration"] = data.cityOfRegistration;
      fields["teamName"] = data.teamName;
      fields["teamID"] = data.teamID;
      fields["userId"] = data.userId;


      fields["agegroup"] = data.agegroup;
      fields["id"] = data.id;


      console.log("data.agegroup", data.agegroup)

      setCities(citylist?.map((city) => city.name));
      setTeams(teamsFind?.map((team) => team.name));
    } catch (error) {
      navigate('/')

    }
  }

  // Use effect to log fields whenever fields are updated
  useEffect(() => {

    if (token) {
      tokenVerify();
    }

  }, [token, citylist]);

  console.log("Teams", cities, teams)

  if (loading || cityLoading | teamLoading || competitiveLoading) {
    return <Loader />
  }

  if (!token) {
    navigate('/')
  }

  return (
    <div className="">
      <ToastContainer />
      <form
        onSubmit={handleRegister}
        className="md:pr-2 w-full mt-12 sm:pr-2 md:ml-1 md:pl-2 sm:ml-2 sm:pl-2 md:flex "
      >
        <div className="md:w-2/5 ml-2 mr-2">
          <div className="text-3xl font-bold uppercase">SIGNUP FOR Competitive (PLAYER)</div>

          <Input
            value={fields["participantName"]}
            placeholder={'Participant'}
            fieldName={"participantName"}
            handleChange={handleInputValue}
            type="text"
            className={''}
            required={true}
          />

          <Input
            value={fields["dateOfBirth"]}
            placeholder={'Date of Birth'}
            handleChange={handleInputValue}
            fieldName={"dateOfBirth"}

            type="date"
            className={''}
            required={true}
          />


          <SelectOptions
            placeholder={"Age"}
            value={fields["agegroup"]}
            fieldName={"age"}
            handleChange={handleInputValue}
            options={ages}
            disabled={true}
            className={'opacity-50'}
          />
          <div>




            <SelectOptions
              placeholder={"City of Registration"}
              value={fields["cityOfRegistration"]}
              options={citylist}
              fieldName={"cityOfRegistration"}
              handleChange={handleInputValue}
              disabled={true}
              className={'opacity-50'}


            />
          </div>

          <SelectOptions
            placeholder={"Name of Registered Team"}
            value={fields["teamName"]}
            options={teams}
            fieldName={"teamName"}
            handleChange={handleInputValue}
            disabled={true}
            className={'opacity-50'}


          />



          <FileUpload
            value={fields["profile"]}
            placeholder={'Upload Profile Picture'}
            handleChange={handleInputValue}
            type="file"
            className={''}
            required={false}
            fieldName={"profile"}

          />
          <Input
            value={fields["HEIGHT"]}
            placeholder={"Height  (FOOTS)"}
            handleChange={handleInputValue}
            type="text"
            className={''}
            required={true}
            fieldName={"HEIGHT"}
            inputText = {`6'5"`}

          />
          <Input
            value={fields["WEIGHT"]}
            placeholder={'Weight (LBS)'}
            handleChange={handleInputValue}
            type="number"
            className={''}
            required={true}
            fieldName={"WEIGHT"}

          />

          <Input
            value={fields["H_S"]}
            placeholder={'Years Played in HS'}
            handleChange={handleInputValue}
            type="number"
            className={''}
            required={true}
            fieldName={"H_S"}

          />

          <Input
            value={fields["COLLEGE"]}
            placeholder={'Years Played in College'}
            handleChange={handleInputValue}
            type="number"
            className={''}
            required={true}
            fieldName={"COLLEGE"}

          />

          <Input
            value={fields["NBA"]}
            placeholder={'Years played as pro'}
            handleChange={handleInputValue}
            type="number"
            className={''}
            required={true}
            fieldName={"NBA"}

          />

          <Input
            value={fields["phoneNumber"]}
            placeholder={'Phone Number'}
            handleChange={handleInputValue}
            type="number"
            className={''}
            required={true}
            fieldName={"phoneNumber"}

          />



          <FileUploadDocument
            value={fields["document"]}
            placeholder={'upload document'}
            fieldName={'document'}
            handleChange={handleInputValue}
            type="file"
            className={''}
            required={false}
          />

          <div className="flex">

            <Checkbox
              fieldName="isAdult"
              onChange={handleInputValue}



            />
            <p>
              You are this person's parent or legal guardian You must be the parent or legal guardian to register someone under 18 years old. as of 2021-2022 school year
            </p>
          </div>

          <div className="grade">
            <p
              className="text-red-400 uppercase">
              please confirm your child's grade next fall
            </p>

            <SelectOptions
              placeholder={"Grade"}
              value={fields["grade"]}
              options={grade}
              handleChange={handleInputValue}
              fieldName={"grade"}
            />




            <Input
              value={fields["email"]}
              placeholder={'Email address'}
              handleChange={handleInputValue}
              type="email"
              disabled={true}
              required={true}
              fieldName={"email"}
              className={'opacity-50'}

            />

            <div>

              <RadioButtonGroup
                name={"isFirstYearPlaying"}
                fieldName={"isFirstYearPlaying"}
                onChange={handleInputValue}
                options={YesNo}
                required={true}
                value={fields["isFirstYearPlaying"]}
                label={"Is 2025 your child's first year playing in the Breaking Boundries League ?"}
              />


            </div>


            <div>

              <SelectOptions
                placeholder={"Leagues Jersey Size"}
                value={fields["jerseySize"]}
                options={jerseySizes}
                handleChange={handleInputValue}
                fieldName={"jerseySize"}

              />
              <p className="text-sm font-thin">
                Each player will receive their camp jersey on first day play
              </p>

            </div>


            <div>

              <Input
                value={fields["schoolName"]}
                placeholder={'School Name'}
                handleChange={handleInputValue}
                type="text"
                className={''}
                required={true}
                fieldName={"schoolName"}

              />
              <p className="text-sm font-thin">
                Please provide full name of your child's school.

              </p>

            </div>


            <div>

              <RadioButtonGroup
                name={"isFirstYearPlaying"}
                fieldName={"isMemberOfOtherTeam"}
                onChange={handleInputValue}
                options={YesNo}
                required={true}
                value={fields["isMemberOfOtherTeam"]}
                label={"IIs the participant currently member of a team or club other than Bay Area 3x3 League ?"}

              />


            </div>



            <Input
              value={fields["clubName"]}
              placeholder={'Name of Club'}
              handleChange={handleInputValue}
              type="text"
              className={''}
              required={true}
              fieldName={"clubName"}

            />



            <div>

              <SelectOptions
                placeholder={"How did you hear about The Bay Area Breaking Boundries League?"}
                value={fields["heardAboutLeague"]}
                options={howDidYouHearOptions}
                handleChange={handleInputValue}
                fieldName={"heardAboutLeague"}

              />
              <p className="text-sm font-thin text-red-700">
                Bay Area 3x3 Breaking Boundries Refund is offered by Bay Area 3x3 Breaking Boundries Network during the registration process if you would like to protect your camp registration. This refundable registration product is optional and may be purchased ONLY during the registration process at checkout. It is not refundable or transferable and is not available for purchase after registration is complete.              </p>



            </div>




          </div>

          <div className="my-2 Emergency and Security Information">
            <h1 className="p-0">
              Emergency and Security Information
            </h1>
            <p>

              Other than you as the parent/guardian, please provide a secondary emergency contact. This person will also have permission to pick-up your child. Additional contacts may be submitted before camp starts through an online supplemental form.
            </p>
          </div>

          <div className="Primary Contact my-4">
            <div>
              <h1 className="text-2xl p-0">
                Primary Emergency Contact

              </h1>

              <SelectOptions
                placeholder={"who is the primary emergency contact person other than you?"}
                value={fields["primaryPersonName"]}
                options={familyOptions}
                fieldName={"primaryPersonName"}
                handleChange={handleInputValue}
              />
            </div>



            <Input
              value={fields["primaryCallPhoneNumber"]}
              placeholder={'Primary Person Phone'}
              handleChange={handleInputValue}
              type="number"
              className={''}
              required={true}
              fieldName={"primaryCallPhoneNumber"}

            />


            <Input
              value={fields["primaryEmergencyContactEmail"]}
              placeholder={'Primary Emergency Contact Email'}
              handleChange={handleInputValue}
              type="email"
              className={''}
              required={true}
              fieldName={"primaryEmergencyContactEmail"}

            />

          </div>


          <div className="Secondary Contact my-4">
            <div>
              <h1 className="text-2xl p-0">
                Secondary Emergency Contact

              </h1>

              <SelectOptions
                placeholder={"who is the secondary emergency contact person other than you?"}
                value={fields["secondaryPersonName"]}
                options={familyOptions}
                fieldName={"secondaryPersonName"}
                handleChange={handleInputValue}

              />
            </div>



            <Input
              value={fields["secondaryCallPhoneNumber"]}
              placeholder={'Secondary Person Phone'}
              handleChange={handleInputValue}
              type="number"
              className={''}
              required={true}
              fieldName={"secondaryCallPhoneNumber"}

            />


            <Input
              value={fields["secondaryEmergencyContactEmail"]}
              placeholder={'Secondary Emergency Contact Email'}
              handleChange={handleInputValue}
              type="email"
              className={''}
              required={true}
              fieldName={"secondaryEmergencyContactEmail"}

            />

          </div>

          <div className="Health Section text">
            <h1 className="p-0">
              Health & Safety Information
            </h1>
            <p>
              We will require a guardian Health and safety rela sWaiver form DATED WITHIN THE LAST YEAR BEFORE YOUR CAMPER'S START DATE.
              Bay Area Breaking Boundries Leage POLICIES DO NOT ALLOW DISPENSING, BY CAMP STAFF, OF ANY MEDICATION WHILE YOUR CAMPER IS AT CAMP. ALL CAMPS HAVE CERTIFIED ATHLETIC TRAINERS ON SITE FOR CPR/FIRST AID AND SAFETY.
            </p>



            <div>

              <RadioButtonGroup
                name={"healthConditions"}
                fieldName={"healthConditions"}
                onChange={handleInputValue}
                options={YesNo}
                required={true}

                value={fields["healthConditions"]}
                label={"Are there any health conditions including physical, psychiatric, or behavioral issues such as Asthma/Respiratory, ADD/ADHD, Anxiety/Depression, or Cardiac Conditions which we need to be aware? If yes, you will need to provide the details."}
              />
              {fields["healthConditions"] === "true" && (
                
                <textarea
                  name="message"
                  handleChange={handleInputValue}
                  rows={4} // Default rows for textarea
                  placeholder="Message" required>
                    {fields["message"]}
                </textarea>
              )}

            </div>
          </div>


          <div className="SPrimary Parent / Guardian">
            <h1 className="text-2xl p-0">
              Primary Parent / Guardian

            </h1>



            <Input
              value={fields["parentFirstName"]}
              placeholder={'First Name'}
              handleChange={handleInputValue}
              type="text"
              className={''}
              required={true}
              fieldName={"parentFirstName"}

            />

            <Input
              value={fields["parentLastName"]}
              placeholder={'Last Name'}
              handleChange={handleInputValue}
              type="text"
              className={''}
              required={true}
              fieldName={"parentLastName"}

            />

            <Input
              value={fields["parentEmailAddress"]}
              placeholder={'Email Address'}
              handleChange={handleInputValue}
              type="email"
              className={''}
              required={true}
              fieldName={"parentEmailAddress"}
            />



            <Input
              value={fields["parentPhoneNumber"]}
              placeholder={'Cell phone number'}
              handleChange={handleInputValue}
              type="number"
              className={''}
              required={true}
              fieldName={"parentPhoneNumber"}
            />
            <div className="flex gap-2 items-center">

              <Input
                value={fields["parentBusinessPhoneNumber"]}
                placeholder={'Business phone number'}
                handleChange={handleInputValue}
                type="number"
                className={''}
                required={true}
                fieldName={"parentBusinessPhoneNumber"}
              />
              <Input
                value={fields["ext"]}
                placeholder={'ext'}
                handleChange={handleInputValue}
                type="text"
                className={''}
                required={true}
                fieldName={"ext"}
              />

            </div>


            <Input
              value={fields["parentHomePhoneNumber"]}
              placeholder={'Home phone numberr'}
              handleChange={handleInputValue}
              type="number"
              className={''}
              required={true}
              fieldName={"parentHomePhoneNumber"}
            />


            <SelectOptionsNormal
              placeholder={"Country"}
              value={fields["parentCountry"]}
              options={countryList}
              fieldName={"parentCountry"}
              handleChange={handleInputValue}

            />
          </div>

          <div className="termAndConditions">

            <h1 className="text-2xl p-0">
              Waivers and Agreements
            </h1>

            <ul className="pl-5 list-circle py-5 flex flex-col gap-2"> {/* Add custom class for circles */}
              <li className="text-sm ">
                Please read the following waivers and agreements carefully. They include release of liability and waiver of legal rights, and deprive you of the ability to sue certain parties. By agreeing electronically, you acknowledge that you have both read and understood all text presented to you as part of the registration process.
              </li>

              {/* Radio Button for Release of Liability */}
              <li>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="releaseLiability"
                    name="releaseLiability"
                    value="agree"
                    checked={selectedRelease === 'agree'}
                    onChange={handleReleaseChange}
                    required
                    className="mr-2"
                  />
                  <label htmlFor="releaseLiability">I agree to the RELEASE OF LIABILITY AND CANCELLATION POLICY</label>
                </div>
              </li>

              <li>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="activeAgreement"
                    name="activeAgreement"
                    value="agree"
                    checked={selectedActiveAgreement === 'agree'}
                    onChange={handleActiveAgreementChange}
                    required
                    className="mr-2"
                  />
                  <label htmlFor="activeAgreement">I agree to the Active Agreement and Waiver</label>
                </div>
              </li>
            </ul>

          </div>



          <div className="termAndConditions">

            <h1 className="text-2xl p-0">
              Digital Signature
            </h1>

            <ul className="pl-5 list-circle py-5 flex flex-col gap-2"> {/* Add custom class for circles */}

              <li>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="signatureType"
                    name="signatureType"
                    value="agree"
                    checked={selectedRelease === 'agree'}
                    onChange={handleReleaseChange}
                    required
                    className="mr-2"
                  />
                  <label htmlFor="signatureType">
                    Write your signature
                  </label>
                </div>
              </li>

              <li>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="signatureType"
                    name="signatureType"
                    value="agree"
                    checked={selectedActiveAgreement === 'agree'}
                    onChange={handleActiveAgreementChange}
                    required
                    className="mr-2"
                  />
                  <label htmlFor="signatureType">
                    Type your signature
                  </label>                </div>
              </li>
            </ul>

            <p>
              By entering my name below, I assert that I have reviewed and agree to all the waivers and agreements I selected above
            </p>

            <Input
              value={fields["signature"]}
              placeholder={'Electronic signature'}
              handleChange={handleInputValue}
              type="text"
              className={''}
              required={true}
              fieldName={"signature"}
            />


          </div>

          <div className="flex pt-20 mb-5 ml-2 mr-2">
            <button
              type="submit"
              className="font-bold  bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-1"
            >
              Sign Up
            </button>
          </div>
        </div>
        <div className="md:w-3/5 p-4">
          <div className="text-center uppercase whitespace-nowrap text-xl font-bold">
            Sign Up For
          </div>
          <div className="text-center bg-white px-10 py-16">
            <div className="flex flex-wrap justify-center md:gap-[9rem]  md:flex-row flex-col ">
              <NavLink to="/signuptournament">
                <BiBasketball className="inline text-4xl mb-4" />
                <div className="font-bold mb-4 text-sm ">Tournament</div>
              </NavLink>
              <NavLink to="/signupcamp" className="mb-4">
                <FaCampground className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">CAMPS</div>
              </NavLink>
              <NavLink to="/signupevent" className="mb-4">
                <img
                  src="/images/Ticket.svg"
                  alt="Ticket"
                  className="inline h-12 mb-2 w-16"
                />
                <div className="font-bold text-sm mb-4">EVENTS</div>
              </NavLink>
              <NavLink to="/signupvolunteer" className="mb-2">
                <HiOutlineHandRaised className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Volunteer</div>
              </NavLink>
              <NavLink to="/signupworkwithus" className="mb-2">
                <RiSuitcaseLine className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Work with us</div>
              </NavLink>
            </div>
          </div>
        </div>
      </form>
      <div>
        <div className="md:p-20 md:py-10">
          <AdvertisementOne index={0} />
        </div>
        <Teams />
        <div className="bg-[#181B22] md:p-20">
          <AdvertisementOne index={1} />
        </div>
        <div className="md:p-20 p-4 bg-[#181B22] text-white flex flex-col gap-20 py-20">
          <TypoBu
            item={{
              text: "shop",
              text_ssize: "text-3xl",
              text_bsize: "text-4xl",
              border: true,
              button: false,
            }}
          />
          <Shop />
          <div className="text-center">
            <Link
              to="/store"
              className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
            >
              VIEW ALL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};


const Input = memo(({ value, type, disabled, fieldName, handleChange, placeholder, className, required,inputText }) => {

  return <div className="mt-4">
    <h5>{placeholder}</h5>
    <input
      name={fieldName}
      value={value}
      onChange={handleChange}
      placeholder={inputText}
      type={type}
      disabled={disabled}
      required={required}
      className={`border-[#e7e7e7] border-2 rounded-md p-2 w-full ${className}`}
    />

  </div>

})



const SelectOptions = ({ value, disabled, fieldName, options, handleChange, placeholder, className }) => {

  return <div className="mt-4">
    <h5 className="">{placeholder}</h5>
    <select
      name={fieldName}

      id={placeholder}
      value={value}
      onChange={handleChange}
      required={true}
      disabled={disabled}
      className={`border-[#e7e7e7] border-2 rounded-md p-2 w-full ${className}`}

    >
      <option value="" >
        {placeholder}
      </option>
      {options?.map((item, index) => {
        return (
          <option value={item.value} key={index}>
            {item.label}
          </option>
        );
      })}
    </select>
  </div>

};

const SelectOptionsNormal = ({ value, disabled, fieldName, options, handleChange, placeholder, className }) => {

  return <div className="mt-4">
    <h5 className="">{placeholder}</h5>
    <select
      name={fieldName}

      id={placeholder}
      value={value}
      onChange={handleChange}
      required={true}
      disabled={disabled}
      className={`border-[#e7e7e7] border-2 rounded-md p-2 w-full ${className}`}

    >
      <option value="" >
        {placeholder}
      </option>
      {options?.map((item, index) => {
        return (
          <option value={item} key={index}>
            {item}
          </option>
        );
      })}
    </select>
  </div>

};

const FileUpload = ({ value, handleChange, fieldName, placeholder, className, required }) => {

  return <div className="mt-4 mb-2">
    <h5 className="mb-2">{placeholder}</h5>
    <label
      htmlFor="profileInput"
      className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
    >
      {placeholder}
    </label>
    <input
      name={fieldName}

      type="file"
      id="profileInput"
      className="hidden"
      accept="image/*"
      onChange={handleChange}
      required={required}
    />
    {value && value.name && (
      <p className="mt-2 text-gray-600">{value.name}</p>
    )}
  </div>
}



const FileUploadDocument = ({ value, handleChange, fieldName, placeholder, className, required }) => {

  return <div className="mt-4 mb-2">
    <h5 className="mb-2">
      Upload Your Document For Date Of Birth Verification
    </h5>
    <label
      htmlFor="document"
      className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
    >
      {placeholder}
    </label>
    <input
      name={fieldName}

      type="file"
      id="document"
      accept=".pdf"
      className="hidden"
      onChange={handleChange}
      required={required}
    />
    {value && value.name && (
      <p className="mt-2 text-gray-600">{value.name}</p>
    )}
  </div>
}


const RadioButtonGroup = ({
  name,
  fieldName,            // Name to group radio buttons together
  options,         // Array of options [{ value: '...', label: '...' }]
  value,           // The selected value
  onChange,        // Handler for the onChange event
  label,           // Label for the radio button group
  required,        // Whether the field is required
  className,       // Custom class for styling
}) => {
  return (
    <div className={`mt-4 mb-2 ${className}`}>
      <h5 className="mb-2">{label}</h5>

      {options.map((option) => (
        <div key={option.value} className="flex items-center mb-2">
          <input
            type="radio"
            id={option.value}
            name={fieldName}
            value={option.value}   // Value to be submitted if selected
            checked={value === option.value} // Check if this is the selected option
            onChange={onChange}    // Handle change
            required={required}    // Required if set to true
            className="mr-2"       // Add some margin for better spacing
          />
          <label
            htmlFor={option.value}
            className={value === option.value ? 'text-blue-500 font-bold' : 'text-gray-700'}
          >
            {option.label}
          </label>        </div>
      ))}
    </div>
  );
};


const Checkbox = ({
  id,                // Unique ID for the checkbox
  label,             // Label for the checkbox
  checked,           // Whether the checkbox is checked or not
  onChange,          // Event handler for when the checkbox is toggled
  required,          // Whether the checkbox is required
  className,
  fieldName     // Custom class for styling
}) => {
  return (
    <div className={`mt-4 mb-2 ${className}`}>
      <div className="flex items-center">
        <input
          type="checkbox"
          id={id}                   // Set the ID for the checkbox
          checked={checked}         // Whether it's checked or not
          onChange={onChange}       // Trigger onChange when toggled
          required={required}
          name={fieldName}
          // Make the checkbox required (optional)
          className="mr-2"          // Space between checkbox and label
        />
        <label htmlFor={id}>{label}</label>  {/* Display the label next to the checkbox */}
      </div>
    </div>
  );
};


export default SignUpComptetive;
