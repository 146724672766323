import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TypoBu from "../../Components/TypoBu";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  const handleSubmit = () => {
    if (!isAuthenticated) {
      navigate("/register"); // Redirect to the register page
    } else {
      toast.success("Already Registered");
    }
  };

  return (
    <div className="bg-white px-4 py-2">
      <ToastContainer />
      <TypoBu
        item={{
          text: "registrations",
          text_ssize: "text-xl",
          text_bsize: "text-2xl",
          border: true,
          button: false,
        }}
      />
      <div className="m-4">
        <div className="mt-4">
          <h5>City Leagues</h5>
          <select
            name="leagues"
            id="leagues"
            className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
          >
            <option value="default" disabled>
              Select City
            </option>
            {leagues.map((item, index) => {
              return <option key={index}>{item.name}</option>;
            })}
          </select>
        </div>
        <div className="mt-4">
          <h5>Team</h5>
          <select
            name="leagues"
            id="leagues"
            className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
          >
            <option value="default" disabled>
              Team
            </option>
            {leagues.map((item, index) => {
              return <option key={index}>{item.name}</option>;
            })}
          </select>
        </div>
        <div className="mt-4">
          <h5>Name</h5>
          <input
            className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
            placeholder="Name"
          ></input>
        </div>
        <div className="mt-4">
          <h5>Age</h5>
          <select
            name="leagues"
            id="leagues"
            className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
          >
            <option value="default" disabled>
              Age
            </option>
            {leagues.map((item, index) => {
              return <option key={index}>{item.name}</option>;
            })}
          </select>
        </div>
        <div className="mt-4">
          <h5>Email</h5>
          <input
            className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
            placeholder="Email"
          ></input>
        </div>
        <div className="mt-4">
          <h5>Phone</h5>
          <input
            className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
            placeholder="+0"
          ></input>
        </div>
      </div>
      <div className="text-center my-10">
        <span className="px-4 py-3 whitespace-nowrap inline-block w-full">
          <button
            onClick={handleSubmit}
            className="w-full bg-yellow-300 px-4 py-3 "
          >
            Sign Up
          </button>
        </span>
      </div>
    </div>
  );
};
export default Registration;

const leagues = [
  {
    name: "Breaking Boundries",
    city: "city1",
  },
  {
    name: "sonic",
    city: "city1",
  },
  {
    name: "sizzle",
    city: "city2",
  },
  {
    name: "Breaking Boundries",
    city: "city3",
  },
];
