import React, { useState } from "react";
import useSchedule from "../../hooks/useSchedule";
import Loader from "../../Components/Loader";
import { FaMapMarkerAlt, FaCalendarAlt, FaClock } from "react-icons/fa";
import ReactPaginate from "react-paginate";

const ITEMS_PER_PAGE = 10;

const formatDateTime = (dateString, timeString) => {
  const date = new Date(dateString);
  const time = new Date(`1970-01-01T${timeString}Z`);

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  }).format(date);

  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(time);

  return { formattedDate, formattedTime };
};

const ScoreCard = ({ date, time, location, teamA, teamAScore, teamB, teamBScore }) => {
  const { formattedDate, formattedTime } = formatDateTime(date, time);

  return (
    <div className="flex flex-col md:flex-row items-center justify-between border border-gray-200 bg-white rounded-lg shadow-md p-6 mb-4 hover:shadow-lg transition-shadow duration-200">
      {/* Date & Time Section */}
      <div className="flex flex-col items-start w-full md:w-1/4 mb-4 md:mb-0 space-y-2">
        <div className="flex items-center space-x-2">
          <FaCalendarAlt className="w-4 h-4 text-blue-600" />
          <p className="text-sm text-gray-500 font-medium">START DATE</p>
        </div>
        <p className="text-lg text-gray-900 font-bold">{formattedDate}</p>
        <div className="flex items-center space-x-2">
          <FaClock className="w-4 h-4 text-blue-600" />
          <p className="text-gray-700">{formattedTime}</p>
        </div>
      </div>

      {/* Match Details Section */}
      <div className="flex-1 flex flex-col items-center md:items-start px-4">
        <div className="flex items-center justify-center md:justify-start space-x-4 mb-4">
          <div className="flex flex-col items-center md:items-start">
            <span className="text-xl font-bold text-blue-600">{teamA}</span>
            <span className="text-sm text-gray-500">(A)</span>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-2xl font-bold">{teamAScore}</span>
            <span className="text-gray-400">vs</span>
            <span className="text-2xl font-bold">{teamBScore}</span>
          </div>
          <div className="flex flex-col items-center md:items-end">
            <span className="text-xl font-bold text-blue-600">{teamB}</span>
            <span className="text-sm text-gray-500">(H)</span>
          </div>
        </div>
        <div className="flex items-center space-x-2 text-gray-600">
          <FaMapMarkerAlt className="w-4 h-4 text-blue-600" />
          <p className="text-sm">{location}</p>
        </div>
      </div>
    </div>
  );
};

const ScheduleDetails = () => {
  const { listSchedules, loading } = useSchedule();
  const [currentPage, setCurrentPage] = useState(0); // react-paginate uses 0-based indexing

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Loader />
      </div>
    );
  }

  const scheduleArray = listSchedules ? Array.from(listSchedules.entries()) : [];
  const totalPages = Math.ceil(scheduleArray.length / ITEMS_PER_PAGE);
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentSchedules = scheduleArray.slice(startIndex, endIndex);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="max-w-[1400px] bg-gray-50 mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">Schedule Details</h1>
      
      {!listSchedules || scheduleArray.length === 0 ? (
        <div className="w-full p-8 mt-20 text-center bg-white rounded-lg shadow">
          <p className="text-gray-600 text-lg">No schedules available</p>
        </div>
      ) : (
        <>
          <div className="space-y-4">
            {currentSchedules.map(([eventId, data]) => (
              <ScoreCard
                key={eventId}
                date={data.date}
                time={data.time}
                location={data.location}
                teamA={data.firstTeam}
                teamAScore={data.firstScore}
                teamB={data.secondTeam}
                teamBScore={data.secondScore}
              />
            ))}
          </div>

          {totalPages > 1 && (
            <div className="mt-8">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageCount={totalPages}
                onPageChange={handlePageChange}
                containerClassName="flex justify-center items-center space-x-2"
                previousClassName="px-3 py-2 rounded-md bg-white border border-gray-300 hover:bg-gray-50"
                nextClassName="px-3 py-2 rounded-md bg-white border border-gray-300 hover:bg-gray-50"
                pageClassName="px-3 py-2 rounded-md bg-white border border-gray-300 hover:bg-gray-50"
                activeClassName="!bg-blue-600 !text-white !border-blue-600"
                disabledClassName="opacity-50 cursor-not-allowed"
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ScheduleDetails;