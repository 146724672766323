import { BsTrophy } from "react-icons/bs";
import { FaCampground } from "react-icons/fa";
import { BiBasketball } from "react-icons/bi";
import { HiOutlineHandRaised } from "react-icons/hi2";
import Teams from "../../Components/Teams";
import Shop from "../../Components/Shop";
import TypoBu from "../../Components/TypoBu";
import { Link, NavLink } from "react-router-dom";
import SignUpDetail from "../../Components/SignUpDetail";
import Loader from "../../Components/Loader";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import Advertisement from "../../Components/Advertisement";
import useSignUpAsWorkWithUs from "../../hooks/signUpWork";
import { useDispatch, useSelector } from "react-redux";

import useSignUpAsVolunteer from "../../hooks/signUpVolunteer";
import useGetAllCity from "../../hooks/getCityList";
import useGetAllTeams from "../../hooks/getTeamsLists";
import getLeaguesList from "../../hooks/getLeagues";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useNavigate} from "react-router-dom";
import AdvertisementOne from "../../Components/Advertisement/HOMEADVERTISEMENT";
import uploadFile from "../../utils/uploadFile";

const ages = [
  { value: "u7", label: "U7" },
  { value: "u8", label: "U8" },
  { value: "u9", label: "U9" },
  { value: "u10", label: "U10" },
  { value: "u11", label: "U11" },
  { value: "u12", label: "U12" },
  { value: "u13", label: "U13" },
  { value: "u14", label: "U14" },
  { value: "u15", label: "U15" },
  { value: "u17", label: "U17" },
  { value: "u18", label: "U18" },
  { value: "u19-u39", label: "U19-U39" },
  { value: "over u40", label: "Over U40" }
];

const SignUpWorkWithUs = () => {
  const { citylist } = useGetAllCity();
  const navigate =useNavigate()
  const { teamslist } = useGetAllTeams();
  const { leagues } = getLeaguesList();
  const {
    user,
    userDetails,
    loading: userLoading,
    error: userError,
  } = useSelector((state) => state.user);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailId, setEmailId] = useState(userDetails["email"] || "");
  const [cellPhone, setCellPhone] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [ext, setExt] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [country, setCountry] = useState("");
   
  const [agegroup, setAgegroup] = useState(userDetails["custom:age"] || "");
  const [ageGroupError, setAgeGroupError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(userDetails["phone_number"] || "");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [parentEmailAddressError, setParentEmailAddressError] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [isParentGuardian, setIsParentGuardian] = useState(false);
  const [isMemberOfTeamOrClub, setIsMemberOfTeamOrClub] = useState(false);
  const [clubName, setClubName] = useState("");
  const [secondaryEmergencyContact, setSecondaryEmergencyContact] =
    useState("");
    const [primaryEmergencyContact,setPrimaryEmergencyContact] =useState("");
    const [primaryEmergencyContactError,setPrimaryEmergencyContactError] =useState("");
    const [primaryEmergencyContactEmail, setPrimaryEmergencyContactEmail] =
    useState("");
  const [parentEmailAddress, setParentEmailAddress] = useState("");

  const [primaryCallPhoneNumber, setPrimaryCallPhoneNumber] = useState("");
  const [secondaryEmergencyContactEmail, setSecondaryEmergencyContactEmail] =
    useState("");
  const [secondaryCallPhoneNumber, setSecondaryCallPhoneNumber] = useState("");
  const [emergencyContactEmail, setEmergencyContactEmail] = useState("");
  const [callPhoneNumber, setCallPhoneNumber] = useState("");
  const [howDidYouHear, setHowDidYouHear] = useState("");
  const [healthConditions, setHealthConditions] = useState(false);
  const [agreeRelease, setAgreeRelease] = useState(false);
  const [agreeActive, setAgreeActive] = useState(false);
  const [signatureType, setSignatureType] = useState(""); // Default value
  const [electronicSignature, setElectronicSignature] = useState("");
  const [participant, setParticipant] = useState( userDetails["name"] || "");
  const [emailAddress, setEmailAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [jobApplyingFor, setJobApplyingFor] = useState("");
  const [jobApplyingForError, setJobApplyingForError] = useState("");
  const [preferredCity, setPreferredCity] = useState("");
  const [preferredCityError, setPreferredCityError] = useState("");
  const [resume, setResume] = useState(null);
  const [resumeError, setResumeError] = useState(null);
  const { signUpWorkWithUsFunc,loading: creating } = useSignUpAsWorkWithUs();
  const [participantError, setParticipantError] = useState("");
  const [cityOfRegistrationError, setCityOfRegistrationError] = useState("");
  const [nameOfRegisteredTeamError, setNameOfRegisteredTeamError] =
    useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [cellPhoneError, setCellPhoneError] = useState("");
  const [businessPhoneError, setBusinessPhoneError] = useState("");
  const [extError, setExtError] = useState("");
  const [homePhoneError, setHomePhoneError] = useState("");
  const [profileError, setProfileError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [isParentGuardianError, setIsParentGuardianError] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [gender, setGender] = useState('');
  const [shirtSize, setShirtSize] = useState('');
  const [genderError, setGenderError] = useState('');
  const [shirtSizeError,setShirtSizeError]=useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [schoolNameError, setSchoolNameError] = useState("");
  const [isMemberOfLeagueError, setIsMemberOfLeagueError] = useState("");

  const [isMemberOfTeamOrClubError, setIsMemberOfTeamOrClubError] =
    useState("");
  const [clubNameError, setClubNameError] = useState("");
  const [secondaryEmergencyContactError, setSecondaryEmergencyContactError] =
    useState("");
  const [emergencyContactEmailError, setEmergencyContactEmailError] =
    useState("");
  const [callPhoneNumberError, setCallPhoneNumberError] = useState("");
  const [howDidYouHearError, setHowDidYouHearError] = useState("");
  const [healthConditionsError, setHealthConditionsError] = useState("");
  const [agreeReleaseError, setAgreeReleaseError] = useState("");
  const [agreeActiveError, setAgreeActiveError] = useState("");
  const [signatureTypeError, setSignatureTypeError] = useState("");
  const [electronicSignatureError, setElectronicSignatureError] = useState("");
  const [secondaryEmergencyContactEmailError,setSecondaryEmergencyContactEmailError] = useState("");
  const [secondaryCallPhoneNumberError, setSecondaryCallPhoneNumberError] = useState("");
  const [primaryEmergencyContactEmailError,setPrimaryEmergencyContactEmailError] = useState("");
  const [primaryCallPhoneNumberError, setPrimaryCallPhoneNumberError] =useState("");
  // Event handlers for the new input fields
  const handleParticipantChange = (e) => {
    setParticipant(e.target.value);
    setParticipantError("");
  };
  const handleDateOfBirthChange = (e) => {
    setDateOfBirth(e.target.value);
    setDateOfBirthError("");
  };
  const handleEmailIdChange = (e) => {
    setEmailId(e.target.value);
    setEmailIdError("");
  };
  const handleAgeGroup = (e) => {
    setAgegroup(e.target.value);
    setAgeGroupError("");
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setPhoneError("");
  };

  const handleJobApplyingForChange = (e) => {
    setJobApplyingFor(e.target.value);
    setJobApplyingForError("");
  };

  const handlePreferredCityChange = (e) => {
    setPreferredCity(e.target.value);
    setPreferredCityError("");
  };

  const handleResumeChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      setResume(file);
    } else {
      setResume(null);
    }

    setResumeError("");
  };

  const stateSetters = {
    message,
    setMessage,
    showMessage,
    setShowMessage,
    setSecondaryEmergencyContact,
    secondaryEmergencyContact,
    setPrimaryEmergencyContact,
    primaryEmergencyContact,
    setFirstName,
    firstName,
    setPhoneNumberError,
    phoneNumberError,
    setPhoneNumber,
    phoneNumber,
    setLastName,
    lastName,
    setEmail,
    email,
    setCellPhone,
    cellPhone,
    setBusinessPhone,
    businessPhone,
    setExt,
    ext,
    setHomePhone,
    homePhone,
    setCountry,
    country,
    setShirtSize,
    setDateOfBirth,
    dateOfBirth,
    setIsParentGuardian,
    isParentGuardian,
    setIsMemberOfTeamOrClub,
    isMemberOfTeamOrClub,
    setClubName,
    parentEmailAddress,
    setParentEmailAddress,
    clubName,
    setSecondaryEmergencyContact,
    secondaryEmergencyContact,
    setPrimaryEmergencyContact,
    primaryEmergencyContact,
    setPrimaryEmergencyContactEmail,
    primaryEmergencyContactEmail,
    setPrimaryCallPhoneNumber,
    primaryCallPhoneNumber,
    setSecondaryEmergencyContactEmail,
    secondaryEmergencyContactEmail,
    setSecondaryCallPhoneNumber,
    secondaryCallPhoneNumber,
    setHowDidYouHear,
    howDidYouHear,
    setHealthConditions,
    setGenderError,
    genderError,
    healthConditions,
    setAgreeRelease,
    agreeRelease,
    setAgreeActive,
    agreeActive,
    setSignatureType,
    signatureType,
    setElectronicSignature,
    electronicSignature,
    setFirstNameError,
    firstNameError,
    setLastNameError,
    lastNameError,
    setEmailError,
    emailError,
    gender,
    setGender,
    setCellPhoneError,
    cellPhoneError,
    setBusinessPhoneError,
    businessPhoneError,
    setExtError,
    extError,
    setHomePhoneError,
    homePhoneError,
    setCountryError,
    countryError,
    setDateOfBirthError,
    dateOfBirthError,
    setIsParentGuardianError,
    isParentGuardianError,
    setShirtSizeError,
    shirtSizeError,
    setGenderError,
    genderError,
    setEmailAddressError,
    emailAddressError,
    setSchoolNameError,
    schoolNameError,
    setIsMemberOfLeagueError,
    isMemberOfLeagueError,
    setIsMemberOfTeamOrClubError,
    isMemberOfTeamOrClubError,
    setClubNameError,
    clubNameError,
    setSecondaryEmergencyContactError,
    secondaryEmergencyContactError,
    setPrimaryEmergencyContactError,
    primaryEmergencyContactError,
    setPrimaryEmergencyContactEmailError,
    primaryEmergencyContactEmailError,
    setPrimaryCallPhoneNumberError,
    primaryCallPhoneNumberError,
    setSecondaryEmergencyContactEmailError,
    secondaryEmergencyContactEmailError,
    setSecondaryCallPhoneNumberError,
    secondaryCallPhoneNumberError,
    setHowDidYouHearError,
    howDidYouHearError,
    setHealthConditionsError,
    healthConditionsError,
    setAgreeReleaseError,
    agreeReleaseError,
    setAgreeActiveError,
    agreeActiveError,
    setSignatureTypeError,
    signatureTypeError,
    setElectronicSignatureError,
    electronicSignatureError,
    setParentEmailAddressError,

  };
 
  const handleRegister = async () => {
    // const resumeLink = await uploadFile(resume);
    
    const data = {
      userId: user,
      participantName: participant,
      email: emailId,
      // phone,
      transactionStatus:"PENDING",
      agegroup,
      ext,
      phoneNumber,
      applyingFor: jobApplyingFor,
      preferredCityOfEvent: preferredCity,
      resume ,
      dateOfBirth,
      isAdult: isParentGuardian,
      shirtSize,
      isMemberOfOtherClub:isMemberOfTeamOrClub,
      clubName,
      gender,
      secondaryPersonName: secondaryEmergencyContact,
      primaryPersonName: primaryEmergencyContact,
      primaryCallPhoneNumber,
      secondaryCallPhoneNumber,
      primaryEmergencyContactEmail,
      secondaryEmergencyContactEmail,
      heardAboutLeague: howDidYouHear,
      healthConditions,
      parentFirstName: firstName,
      parentLastName: lastName,
      parentEmailAddress,
      parentPhoneNumber: cellPhone,
      parentBusinessPhoneNumber: businessPhone,
      parentHomePhoneNumber: homePhone,
      parentCountry: country,
      signature: electronicSignature
    };
    const checkRequiredFields = () => {
      const requiredFields = [
        participant,
        emailId,
        resume,
        phoneNumber,
        agegroup,
        preferredCity,
        jobApplyingFor,
        firstName,
        lastName,
        cellPhone,
        businessPhone,
        ext,
        homePhone,
        dateOfBirth,
        parentEmailAddress,
        clubName,
        country,
        isParentGuardian,
        gender,
        isMemberOfTeamOrClub,
        secondaryEmergencyContact,
        primaryEmergencyContact,
        secondaryEmergencyContactEmail,
        primaryEmergencyContactEmail,
        secondaryCallPhoneNumber,
        primaryCallPhoneNumber,
        howDidYouHear,
        healthConditions,
        agreeRelease,
        agreeActive,
        electronicSignature,
      ];
  
      // Check if any required field is empty
      const isEmpty = requiredFields.some((field) => field === '');
  
      return !isEmpty; // Return true if all required fields are filled
    };
    const allRequiredFilled = checkRequiredFields();
    // console.log("Data",allRequiredFilled, data);
    if (allRequiredFilled) {
      localStorage.setItem("formData", JSON.stringify(data));
      // navigate("/session/workwithus");
      
      await signUpWorkWithUsFunc(data);
   
    }
     
   

    setParticipantError("");
    setAgeGroupError("");

    setCityOfRegistrationError("");
    setNameOfRegisteredTeamError("");
    setEmailIdError("");
    setPhoneNumberError("");
    setJobApplyingForError("");
    setPreferredCityError("");
    setResumeError("");
    setParticipantError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setCellPhoneError("");
    setBusinessPhoneError("");
    setExtError("");
    setHomePhoneError("");
    setCountryError("");
    setDateOfBirthError("");
    setIsParentGuardianError("");
    setGenderError("");
    setEmailAddressError("");
    setSchoolNameError("");
    setIsMemberOfLeagueError("");
    setShirtSizeError("");
    setIsMemberOfTeamOrClubError("");
    setClubNameError("");
    setSecondaryEmergencyContactError("");
    setEmergencyContactEmailError("");
    setCallPhoneNumberError("");
    setHowDidYouHearError("");
    setHealthConditionsError("");
    setAgreeReleaseError("");
    setAgreeActiveError("");
    setSignatureTypeError("");
    setElectronicSignatureError("");
    setParentEmailAddressError("");
       
    let hasErrors = false; // Validation functions

    if (!firstName) {
      setFirstNameError("First Name is required.");
      hasErrors = true;
    }

    if (!lastName) {
      setLastNameError("Last Name is required.");
      hasErrors = true;
    }

    if (!cellPhone) {
      setCellPhoneError("Cell Phone is required.");
      hasErrors = true;
    }

    if (!businessPhone) {
      setBusinessPhoneError("Business Phone is required.");
      hasErrors = true;
    }

    if (!ext) {
      setExtError("Ext is required.");
      hasErrors = true;
    }
    if (!agegroup) {
      setAgeGroupError("Please enter the Age Group");
      hasErrors = true;
    }
    if (!parentEmailAddress) {
      setParentEmailAddressError("Email Address is required.");
      hasErrors = true;
    }
    if (!phoneNumber) {
      setPhoneNumberError("Phone Number is required.");
      hasErrors = true;
    }
    if (!homePhone) {
      setHomePhoneError("Home Phone is required.");
      hasErrors = true;
    }

    if (!participant) {
      setParticipantError("Participant is required.");
      hasErrors = true;
    }

    if (!jobApplyingFor) {
      setJobApplyingForError("Job Applying For is required.");
      hasErrors = true;
    }

    if (!preferredCity) {
      setPreferredCityError("Preferred City is required.");
      hasErrors = true;
    }

    if (!emailId) {
      setEmailIdError("Email Address is required.");
      hasErrors = true;
    }

    if (!resume) {
      setResumeError("Resume is required.");
      hasErrors = true;
    }
    // if (!phone) {
    //   setPhoneError("Phone is required.");
    //   hasErrors = true;
    // }
    
    if (!dateOfBirth) {
      setDateOfBirthError("Date of Birth is required.");
      hasErrors = true;
    }
    if (!parentEmailAddress) {
      setEmailAddressError("Email Address is required.");
      hasErrors = true;
    }
   
    if (!clubName) {
      setClubNameError("Club Name is required.");
      hasErrors = true;
    }
 
    if (!country) {
      setCountryError("Country is required.");
      hasErrors = true;
    }

    if (!isParentGuardian) {
      setIsParentGuardianError("Is Parent Guardian is required.");
      hasErrors = true;
    }

    if (!gender) {
      setGenderError("Gender is required.");
      hasErrors = true;
    }

    if (!parentEmailAddress) {
      setEmailAddressError("Email Address is required.");
      hasErrors = true;
    }

    if (!isMemberOfTeamOrClub) {
      setIsMemberOfTeamOrClubError("Is Member Of Team Or Club is required.");
      hasErrors = true;
    }

    if (!secondaryEmergencyContact) {
      setSecondaryEmergencyContactError(
        "Secondary Emergency Contact is required."
      );
      hasErrors = true;
    }
    if (!primaryEmergencyContact) {
      setPrimaryEmergencyContactError("Primary Emergency Contact is required.");
      hasErrors = true;
    }
    if (!secondaryEmergencyContactEmail) {
      setSecondaryEmergencyContactEmailError("Email is required.");
      hasErrors = true;
    }
    if (!primaryEmergencyContactEmail) {
      setPrimaryEmergencyContactEmailError("Email is required.");
      hasErrors = true;
    }
    if (!secondaryCallPhoneNumber) {
      setSecondaryCallPhoneNumberError("Cell Phone Number is required.");
      hasErrors = true;
    }
    if (!primaryCallPhoneNumber) {
      setPrimaryCallPhoneNumberError("Cell Phone Number is required.");
      hasErrors = true;
    }
    if (!howDidYouHear) {
      setHowDidYouHearError("How Did You Hear is required.");
      hasErrors = true;
    }

    if (!healthConditions) {
      setHealthConditionsError("Health Condition is required.");
      hasErrors = true;
    }

    if (!agreeRelease) {
      setAgreeReleaseError("Agree Release is required.");
      hasErrors = true;
    }

    if (!agreeActive) {
      setAgreeActiveError("Agree Active is required.");
      hasErrors = true;
    }

    // if (!signatureType) {
    //   setSignatureTypeError("Signature Type is required.");
    //     hasErrors = true;
    // }

    if (!electronicSignature) {
      setElectronicSignatureError("Electronic Signature is required.");
      hasErrors = true;
    }

    if (hasErrors) {
      // Display validation errors and prevent form submission
      return;
    } 
  };

  if(creating){
    return <Loader />;
  }
  return (
    <div className="">
      <ToastContainer/>
      <div  className="md:pr-2 mt-12 sm:pr-2 md:ml-1 md:pl-2 sm:ml-2 sm:pl-2 md:flex ">
        <div className="md:w-2/5 ml-2 mr-2">
          <div className="text-3xl font-bold">WORK WITH US</div>
          <div className="mt-4">
            <h5>Participant</h5>
            <input
              name="participant"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={participant}
           
              onChange={handleParticipantChange}
            />
            <span className="text-red-500">{participantError}</span>
          </div>
          <div className="mt-4">
            <h5>Date of birth</h5>
            <input
              type="date"
              value={dateOfBirth}
           
              className="border border-2"
              onChange={handleDateOfBirthChange}
            />
          </div>
          <span className="text-red-500 pb-2">{dateOfBirthError}</span>
          <div className="mt-4">
            <h5>Email Address</h5>
            <input
              name="email"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={emailId}
              
              onChange={handleEmailIdChange}
            />
          <span className="text-red-500 pb-2">{emailIdError}</span>

          </div>
          {/* <div className="mt-4">
            <h5>Phone</h5>
            <input
              name="phone"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={phone}
             
              onChange={handlePhoneChange}
            />
          <span className="text-red-500 pb-2">{phoneError}</span>

          </div> */}
            <div className="mt-4">
            <h5 className="">Age</h5>
            <select
              name="age"
              id="age"
              value={agegroup}
              onChange={handleAgeGroup}
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
            >
              <option value="">
                Age
              </option>
              {ages.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}

            </select>
          </div>
          <span className="text-red-500 pb-2">{ageGroupError}</span>
          <div className="mt-4">
            <h5>Job Applying for</h5>
            <select
              name="job_apply"
              id="job_apply"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={jobApplyingFor}
            
              onChange={handleJobApplyingForChange}
            >
              <option value="">Choose Job Applying For</option>
              <option value="work with us">Work with us</option>
            </select>
          </div>
          <span className="text-red-500 pb-2">{jobApplyingForError}</span>

          <div className="mt-4">
            <h5>Preferred City Of the event</h5>
            <select
              name="prefered_city"
              id="prefered_city"
              className="  border-[#e7e7e7] border-2 rounded-md p-2 w-full "
              value={preferredCity}
            
              onChange={handlePreferredCityChange}
            >
              <option value="">Select City</option>
              {
                citylist?.map((value,index) => (
                  <option value={value.name} key={index}>{value.name}</option>

                ))
              }
            </select>
          </div>
          <span className="text-red-500 pb-2">{preferredCityError}</span>

          <div className="mt-4">
            <h5 className="mb-2">Upload Your Resume</h5>
            <label
              htmlFor="documentInput"
              className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
            >
              Upload Resume
            </label>
            <input
              type="file"
              id="documentInput"
            
              className="hidden"
              onChange={handleResumeChange}
            />

            {resume && <p className="mt-2">Selected File: {resume.name}</p>}
          </div>
          <span className="text-red-500 pt-2 pb-2">{resumeError}</span>
          <SignUpDetail stateSetters={stateSetters} />
          <div className="flex pt-20 mb-5 ml-2 mr-2">
            <button
            
              onClick={handleRegister}
              className="font-bold  bg-[#fec749] lg:px-40 px-20 whitespace-nowrap py-1"
            >
              Sign Up{" "}
            </button>
          </div>
        </div>

        <div className="md:w-3/5 p-4">
          <div className="text-center uppercase whitespace-nowrap text-xl font-bold">
            Sign Up For
          </div>
          <div className="text-center bg-white px-10 py-16">
            <div className="flex flex-wrap justify-center md:gap-[8rem]  md:flex-row flex-col ">
              <NavLink to="/signupleague" className="mb-2">
                <BsTrophy className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">LEAGUE(Player)</div>
              </NavLink>
              <NavLink to="/signuptournament" className="mb-2">
                <BiBasketball className="inline text-4xl mb-4" />
                <div className="font-bold mb-4 text-sm whitespace-nowrap">
                  Tournament
                </div>
              </NavLink>
              <NavLink to="/signupcamp" className="mb-2">
                <FaCampground className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">CAMPS</div>
              </NavLink>
              <NavLink to="/signupevent" className="mb-2">
                <img
                  src="/images/Ticket.svg"
                  alt="Ticket"
                  className="inline h-12 mb-2 w-16"
                />
                <div className="font-bold text-sm mb-4">EVENTS</div>
              </NavLink>
              <NavLink to="/signupvolunteer" className="mb-2">
                <HiOutlineHandRaised className="inline text-4xl mb-4" />
                <div className="font-bold text-sm mb-4">Volunteer</div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#181B22] text-white">
        <div className="md:p-20">
          <AdvertisementOne index={0}/>
        </div>
        <Teams />
        <div className="md:p-20 b-[#181B22]">
          <AdvertisementOne index={1} />
        </div>
        <div className="md:p-20 p-4 b-[#181B22] flex flex-col gap-20 py-20">
          <TypoBu
            item={{
              text: "shop",
              text_ssize: "text-3xl",
              text_bsize: "text-4xl",
              border: true,
              button: false,
            }}
          />
          <Shop />
          <div className="text-center">
            <Link
              to="/store"
              className="px-20 py-2 whitespace-nowrap border-white border rounded-sm"
            >
              VIEW ALL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUpWorkWithUs;
