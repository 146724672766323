/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import * as queries from "../graphql/queries"; // Make sure to import your GraphQL queries
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const useSchedule = () => {
  const [listSchedules, setSchedule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchScores = async () => {
      try {
        setLoading(true);
       
        const response = await API.graphql({
          query: queries.listSchedules,
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        setSchedule(response?.data?.listSchedules?.items || []);
        setLoading(false);
        return response?.data?.listSchedules?.items || [];
 
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error("Error fetching scores:", error);
      }
    };

    fetchScores();
  }, []); 

 

  return { listSchedules, error, loading };
};

export default useSchedule;
